var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseModal',{attrs:{"name":"Modal-Edit","id":"Modal-Edit","idModal":"Modal-Edit","size":"lg","title":"Edição de Campanha"},on:{"shown":function($event){return _vm.restCurent()}},scopedSlots:_vm._u([{key:"footer",fn:function({ cancel }){return [_c('BaseButton',{staticClass:"mr-4",attrs:{"variant":"link-info"},on:{"click":cancel}},[_vm._v(" Fechar ")]),_c('BaseButton',{staticClass:"buttonGreenn",attrs:{"variant":"info","disabled":_vm.loading},on:{"click":_vm.update}},[_vm._v(" Salvar alterações ")])]}}])},[_c('div',[_c('p',{staticClass:"desc"},[_vm._v(" Aqui você pode editar a capa e o título de sua campanha ")])]),_c('img',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"invisible",attrs:{"src":require("../../assets/img/img_null.png"),"alt":"imagem invisível"}}),_c('b-tabs',{staticClass:"mt-4",attrs:{"nav-class":"TabHeader no-border padding","active-nav-item-class":"TabHeader--active","no-nav-style":true}},[_c('b-tab',{attrs:{"title":`Editar título`,"active":""}},[_c('b-container',{staticClass:"mt-1 wrapper container-user",attrs:{"fluid":""}},[_c('div',{staticClass:"Campanha-nome"},[_c('b-form-group',{attrs:{"label":"Edite o título da sua campanha"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true, min: 3, max: 50 }),expression:"{ required: true, min: 3, max: 50 }"}],style:({
                border: _vm.errors.has('title')
                  ? '1px solid var(--red) !important'
                  : '',
              }),attrs:{"name":"title","placeholder":"Adicione um novo título"},model:{value:(_vm.newTitle),callback:function ($$v) {_vm.newTitle=$$v},expression:"newTitle"}}),_c('b-form-invalid-feedback',{staticClass:"titleError text-danger",attrs:{"state":!_vm.errors.has('title')}},[_vm._v(" "+_vm._s(_vm.newTitle.length === 0 ? "Por favor, insira título." : _vm.newTitle.length < 3 || _vm.newTitle.length > 40 ? "Mínimo de 3 caracteres e máximo de 40 caracteres." : "")+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.titleSpace),expression:"titleSpace"}],staticClass:"titleError text-danger"},[_vm._v(" Espaços inválidos. ")])],1)],1)])],1),_c('b-tab',{attrs:{"title":`Enviar arquivos`}},[_c('b-container',{staticClass:"mt-1 wrapper container-user",attrs:{"fluid":""}},[_c('Uploader',{attrs:{"postAction":_vm.returnApi() + 'midia/upload'},on:{"update":function($event){return _vm.fetchMidia('', 'selected')}}})],1)],1),_c('b-tab',{attrs:{"title":`Biblioteca de mídias`}},[_c('b-container',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"mt-1 wrapper container-user",attrs:{"fluid":""}},[_c('div',{staticClass:"flex-grid"},[_c('div',{staticClass:"container-left"},[_c('div',{staticClass:"container-pesquisa"},[_c('div',{staticStyle:{"position":"relative","width":"100%"}},[_c('div',{staticClass:"inputSearch"},[_c('img',{staticClass:"Pointer searchIcon",attrs:{"src":require("@/assets/img/icons/search.svg")}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search_img),expression:"search_img"}],staticClass:"input-busca",attrs:{"type":"text","placeholder":"Pesquisar itens de mídia"},domProps:{"value":(_vm.search_img)},on:{"input":[function($event){if($event.target.composing)return;_vm.search_img=$event.target.value},_vm.debounce]}})])])]),_c('div',{staticClass:"container-img"},_vm._l((_vm.midia),function(img,index){return _c('div',{key:index,staticClass:"imgs"},[(
                    (img.type && img.type === 'image/jpeg') ||
                    img.type === 'image/png' ||
                    img.type === 'image/gif' ||
                    img.type === 'image/webp'
                  )?_c('img',{attrs:{"src":img.url,"alt":"img"},on:{"click":function($event){return _vm.selecionarImg(img)},"~error":function($event){return _vm.setAltImg.apply(null, arguments)}}}):_c('img',{attrs:{"src":require("@/assets/icons/pdf_icon.svg"),"alt":"pdf"},on:{"click":function($event){return _vm.selecionarImg(img)}}})])}),0),_c('div',{staticStyle:{"height":"70px","display":"relative"}},[_c('div',{staticClass:"paginate-container"},[(_vm.midia.length > 0)?_c('Paginate',{attrs:{"totalPages":_vm.pagination.totalPages,"activePage":_vm.pagination.currentPage,"disabled":true},on:{"to-page":_vm.toPage,"per-page":_vm.perPage}}):_vm._e()],1)])]),_c('div',{staticClass:"container-right"},[_c('div',{staticClass:"grid-edit"},[_c('div',[(
                    (_vm.selected_img.type &&
                      _vm.selected_img.type === 'image/jpeg') ||
                    _vm.selected_img.type === 'image/png' ||
                    _vm.selected_img.type === 'image/gif'
                  )?_c('img',{staticClass:"img-style",attrs:{"src":_vm.selected_img.url,"alt":_vm.selected_img.name}}):_c('img',{staticClass:"img-conteudo img-style",attrs:{"src":require("@/assets/img/icons/img_null.svg"),"alt":"imagem-vazia"}})]),_c('div',[_c('div',{staticClass:"img-infos"},[_c('div',[_c('label',[_vm._v("Nome da mídia")]),_c('span',[_vm._v(_vm._s(_vm.selected_img.name))])]),_c('div',[_c('label',[_vm._v("URL da mídia")]),_c('span',[_vm._v(_vm._s(_vm.selected_img.url))])])])])])])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }