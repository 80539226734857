<template>
  <div>
    <BaseModal
      name="Modal-Upload-Title"
      id="Modal-Upload-Title"
      idModal="Modal-Upload-Title"
      size="md"
      title="Edite o título da sua campanha"
    >
      <div class="Campanha-nome">
        <input
          type="text"
          placeholder="Adicione um novo título"
          v-model="newTitle"
          class="w-100"
        />
        <span class="titleError text-danger" v-show="titleRequired">Título obrigatório</span>
        <span class="titleError text-danger" v-show="titleSpace">Espaços inválidos</span>
        <span class="titleError text-danger" v-show="titleLength" v-if="newTitle.length < 6 || newTitle.length > 40">Mínimo 6 caracteres e máximo 40 caracteres</span>
      </div>
      <template v-slot:footer="{ cancel }">
        <div style="display: flex; width: 100%">
          <BaseButton
            variant="link-info"
            class="mr-4"
            style="width: 40%"
            @click="cancel"
          >
            Fechar
          </BaseButton>
          <BaseButton variant="primary" style="width: 60%" @click="updateTitle"
            >Confirmar alteração</BaseButton
          >
        </div>
      </template>
    </BaseModal>
  </div>
</template>
<script>
export default {
  name: "ModalEditTitleCampanha",

  data() {
    return {
      newTitle: "",
    };
  },

  props: ["titleRequired", "titleLength", "titleSpace"],

  methods: {
    updateTitle() {
      this.$emit("edit-title", this.newTitle);
    },
  },
};
</script>

<style scoped lang="scss">
.Campanha-nome {
  margin: 20px 0 30px 0 !important;
  display: flex;
  flex-direction: column;
  gap: 15px;
  .titleError {
    font-size: 12px;
    margin-top: -20px;
    font-weight: 500;
  }
}
</style>
